import React from 'react';
import { LoadingButton } from '@mui/lab';
import Typography from '@mui/material/Typography';

// eslint-disable-next-line react/prop-types
const TextButton = ({ children, typographyProps, ...props }) => (
  <LoadingButton {...props}>
    <Typography {...typographyProps}>
      {children}
    </Typography>
  </LoadingButton>
);

export default TextButton;
